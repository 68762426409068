// ProductPanal.js
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//service
import ProductService from "../../../../services/vo/product-service";
import CartService from "../../../../services/vo/add-to-cart-service";
import AuthService from "../../../../services/vo/auth-service";
import UtilService from "../../../../services/vo/util-service";

//globle state
import { useGlobalStore } from "../../../../utils/global-store/vo/Vo-globalStore";

//components
import ProductDetailsPopup from "./product-details-popup/ProductDetailsPopup";
import ProductSelectPopup from "./product-select-popup/ProductSelectPopup";
import ProductChangePopup from "./product-change-popup/ProductChangePopup";
import ProductCard from "./product-card/ProductCard";
import Loading from "../../shared-components/loading/Loading";

//utils
import OfferDiscount from "../../../../utils/price-utils/offerDiscounts";
import RemainingDays from "../../../../utils/date-utils/remainigDays";
import { Utils } from "../../../../App/one-platform/shared";

function ProductPanal({
  RestrictContinuation,
  userFlowStatus,
  SetIsItemSelected,
  setProductLoaded,
  onNextClicked,
}) {
  const [globalVariable, globalVariabledispatch] = useGlobalStore(true);
  const [loading, SetLoading] = useState(false);
  const [standardProductData, SetStandardProductData] = useState(null);
  const [specialProductData, SetSpecialProductData] = useState(null);
  const [extraProductData, SetExtraProductData] = useState(null);
  const [selectedProductToConfirm, SetSelectedProductToConfirm] =
    useState(null);
  const [selectedMBI, SetSelectedMBI] = useState(null);
  const [selectedExtras, SetSelectedExtras] = useState(null);

  // Track active popup state
  const [activePopup, setActivePopup] = useState({
    type: null, // 'details', 'select', or 'change'
    productId: null,
    section: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    loadProductData();
  }, []);

  const loadProductData = async () => {
    SetLoading(true);
    UpdateCartPricesOnProductSelect();
    if (globalVariable.selectedVehicle) {
      let vendorId = parseInt(
        atob(new URLSearchParams(window.location.search).get("vendor_id"))
      );

      if (userFlowStatus == 0) {
        await loadNewUserProducts(vendorId);
      } else {
        await loadExistingUserProducts();
      }
    }
    SetLoading(false);
  };

  const loadNewUserProducts = async (vendorId) => {
    UtilService.setVendorId(vendorId);
    try {
      const requestData = {
        Language: globalVariable.moduleData?.language,
        Milage: globalVariable.selectedVehicle.mileage,
        ModelYear:
          globalVariable.selectedVehicle.VehicleData.manufacYear + "-01-01",
        VehicleTypeId: globalVariable.selectedVehicle.brandGroupId,
        EnginePower: globalVariable.selectedVehicle?.enginePower,
        EngineCapacity: globalVariable.selectedVehicle?.engineCapacity,
        IsRenewalFlow: false,
        VendorId: vendorId,
      };

      const productsStandard = await ProductService.getStandardProductList(
        requestData
      );
      SetStandardProductData(productsStandard.data.data);

      if (productsStandard.data.data?.length === 0) {
        RestrictContinuation();
      }
      setProductLoaded(true);
    } catch (error) {
      console.error(error);
      setProductLoaded(true);
    }
  };

  const loadExistingUserProducts = async () => {
    UtilService.setVendorId(0);
    const baseRequestData = {
      Language: globalVariable.moduleData?.language,
      Milage: globalVariable.selectedVehicle.mileage,
      ModelYear: globalVariable.selectedVehicle?.ActiveInsurance?.modelYear,
      VehicleTypeId:
        globalVariable.selectedVehicle?.ActiveInsurance?.vehicleTypeId,
      EnginePower: globalVariable.selectedVehicle?.ActiveInsurance?.enginePower,
      EngineCapacity:
        globalVariable.selectedVehicle?.ActiveInsurance?.engineCapacity,
      IsRenewalFlow: true,
      insuranceId: globalVariable.selectedVehicle?.ActiveInsurance?.insuranceId,
      regNo: globalVariable.selectedVehicle?.regNo,
    };

    try {
      const [specialProducts, standardProducts, extraProducts] =
        await Promise.all([
          ProductService.getSpecialProductList({
            insuranceId: baseRequestData.insuranceId,
            milage: baseRequestData.Milage,
            insuranceType:
              globalVariable.selectedVehicle?.ActiveInsurance?.insuranceType,
            regNo: baseRequestData.regNo,
          }),
          ProductService.getStandardRenewalProduct(baseRequestData),
          ProductService.getExtraProductList(baseRequestData),
        ]);

      SetSpecialProductData(specialProducts.data.data);
      SetStandardProductData(standardProducts.data.data);
      SetExtraProductData(extraProducts.data.data);

      const hasNoProducts =
        !specialProducts.data.data?.length &&
        !standardProducts.data.data?.length &&
        !extraProducts.data.data?.length;

      if (hasNoProducts) {
        RestrictContinuation();
      }
      setProductLoaded(true);
    } catch (error) {
      console.error(error);
      setProductLoaded(true);
    }
  };

  const UpdateCartPricesOnProductSelect = () => {
    const MBIInsessionStorage = CartService.getSelectedMBI();
    SetSelectedMBI(MBIInsessionStorage);

    const extrasInsessionStorage = CartService.getSelectedExtras();
    SetSelectedExtras(extrasInsessionStorage);

    updateCartItemPrices(MBIInsessionStorage);
  };

  const updateCartItemPrices = (MBIInsessionStorage) => {
    const sessionStorageCart = CartService.getAllfromCart();
    const newCartItems = [...sessionStorageCart];

    if (MBIInsessionStorage) {
      if (newCartItems.length > 0) {
        SetIsItemSelected(true);
        applyDiscounts(newCartItems, MBIInsessionStorage);
      } else {
        SetIsItemSelected(false);
      }
    } else {
      if (newCartItems.length > 0) {
        SetIsItemSelected(true);
        removeDiscounts(newCartItems);
      } else {
        SetIsItemSelected(false);
      }
    }

    CartService.UpdateCart(newCartItems);
    globalVariabledispatch("SET_CART_ITEMS", newCartItems);
  };

  const applyDiscounts = (items, MBIProduct) => {
    items.forEach((item) => {
      if (item.type === "Extra" && !item.isSpecial && !item.discountedPrice) {
        item.yearlyPaymentOriginal = item.yearlyPayment;
        item.monthlyPaymentOriginal = item.monthlyPayment;
        item.selectedperiodOriginal = { ...item.selectedperiod };
        item.selectedperiod.price = parseFloat(
          OfferDiscount.getDiscountYearlyPrice(item, MBIProduct)
        );
        item.yearlyPayment = parseFloat(
          OfferDiscount.getDiscountYearlyPrice(item, MBIProduct)
        );
        item.monthlyPayment = parseFloat(
          OfferDiscount.getDiscountMonthlyPrice(item, MBIProduct)
        );
        item.discountedPrice = true;
      }
    });
  };

  const removeDiscounts = (items) => {
    items.forEach((item) => {
      if (item.type === "Extra" && item.discountedPrice) {
        item.yearlyPayment = item.yearlyPaymentOriginal;
        item.monthlyPayment = item.monthlyPaymentOriginal;
        item.selectedperiod = item.selectedperiodOriginal;
        item.discountedPrice = false;
      }
    });
  };

  const OnDetailsClick = (offer, isSpecial, section) => {
    if (offer) {
      offer.isSpecial = isSpecial;
      setActivePopup({
        type: "details",
        productId: offer.id,
        section,
      });
    } else {
      setActivePopup({
        type: null,
        productId: null,
        section: null,
      });
    }
  };

  const OnSelectClick = (product, isSpecial, section) => {
    product.isSpecial = isSpecial;
    if (CartService.CheckSameTypeProductSelected(product)) {
      SetSelectedProductToConfirm(product);
      setActivePopup({
        type: "change",
        productId: product.id,
        section,
      });
    } else if (product) {
      setActivePopup({
        type: "select",
        productId: product.id,
        section,
      });
    } else {
      setActivePopup({
        type: null,
        productId: null,
        section: null,
      });
    }
  };

  const OnCloseClick = () => {
    setActivePopup({
      type: null,
      productId: null,
      section: null,
    });
  };

  const onProductChangeConfirmed = (product) => {
    onProductRemove(getSameTypeSelectedProduct(product));
    setActivePopup({
      type: "select",
      productId: product.id,
      section: activePopup.section,
    });
  };

  const onProductRemove = (product) => {
    CartService.removefromCart(product);
    globalVariabledispatch("SET_CART_ITEMS", CartService.getAllfromCart());
    setActivePopup({
      type: null,
      productId: null,
      section: null,
    });

    if (product.type === "MBI") {
      SetSelectedMBI(null);
      SetIsItemSelected(false);
    } else {
      const updatedExtras =
        selectedExtras?.filter((item) => item.id !== product.id) || null;
      SetSelectedExtras(updatedExtras);
    }
    UpdateCartPricesOnProductSelect();
  };

  const OnSelectPopupComplete = (product) => {
    if (product.type === "MBI") {
      SetSelectedMBI(product);
      SetIsItemSelected(true);
      addProductItems(product);
    } else {
      let selectedExtraList = selectedExtras ? [...selectedExtras] : [];
      if (CartService.CheckSameTypeProductSelected(product)) {
        const index = selectedExtraList.findIndex(
          (item) => item.insuranceType === product.insuranceType
        );
        if (index !== -1) {
          selectedExtraList.splice(index, 1);
        }
      }
      selectedExtraList.push(product);
      SetSelectedExtras(selectedExtraList);
      addProductItems(product);
    }
    UpdateCartPricesOnProductSelect();

    if (!Utils.isOnePlatform()) {
      onNextClicked();
    }
  };

  const addProductItems = (product) => {
    product.cultureCode = globalVariable?.moduleData?.language;
    CartService.addtoCart(product);
    globalVariabledispatch("SET_CART_ITEMS", CartService.getAllfromCart());
    setActivePopup({
      type: null,
      productId: null,
      section: null,
    });
  };

  const getSameTypeSelectedProduct = (product) => {
    return CartService.getAllfromCart().find((item) =>
      product.type === "MBI"
        ? item.type === product.type
        : item.insuranceType === product.insuranceType
    );
  };

  const IsProductAvailable = () => {
    return (
      specialProductData?.length > 0 ||
      standardProductData?.length > 0 ||
      extraProductData?.length > 0
    );
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {specialProductData?.length > 0 && userFlowStatus !== 0 && (
            <section className="product-panel product-panel-ext">
              <div className="section-header mb-10">
                <div className="section-info">
                  <div className="main-title main-title-ext">
                    {t("renewal.product_page.product_special_offer_text")}
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "renewal.product_page.product_special_offer_description"
                      ),
                    }}
                  />
                </div>
              </div>
              <ProductCard
                productData={specialProductData}
                selectedMBI={selectedMBI}
                selectedExtras={selectedExtras}
                type="MBI"
                isSpecial={true}
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                onProductRemove={onProductRemove}
                activePopup={activePopup}
                sectionType="special"
              />
            </section>
          )}

          {standardProductData?.length > 0 && (
            <section className="product-panel product-panel-ext">
              <div className="section-header mb-10">
                <div className="section-info">
                  <div className="main-title main-title-ext">
                    {Utils.isOnePlatform()
                      ? t("renewal.product_page.product_standered_text")
                      : t("product_standard_offer_title")}
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{
                      __html: Utils.isOnePlatform()
                        ? t(
                            "renewal.product_page.product_standered_description"
                          )
                        : t("product_standard_offer_description"),
                    }}
                  />
                </div>
              </div>
              <ProductCard
                productData={standardProductData}
                selectedMBI={selectedMBI}
                type="MBI"
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                onProductRemove={onProductRemove}
                isSpecial={false}
                activePopup={activePopup}
                sectionType="standard"
              />
            </section>
          )}

          {extraProductData?.length > 0 && (
            <section className="product-panel product-panel-ext">
              <div className="section-header mb-10">
                <div className="section-info">
                  <div className="main-title main-title-ext">
                    {t("renewal.product_page.product_extra_text")}
                  </div>
                  <div
                    style={{ fontSize: 14 }}
                    dangerouslySetInnerHTML={{
                      __html: t(
                        "renewal.product_page.product_extra_description"
                      ),
                    }}
                  />
                </div>
              </div>
              <ProductCard
                productData={extraProductData}
                selectedMBI={selectedMBI}
                type="Extra"
                OnSelectPopupComplete={OnSelectPopupComplete}
                OnCloseClick={OnCloseClick}
                OnDetailsClick={OnDetailsClick}
                OnSelectClick={OnSelectClick}
                selectedExtras={selectedExtras}
                onProductRemove={onProductRemove}
                isSpecial={false}
                activePopup={activePopup}
                sectionType="extra"
              />
            </section>
          )}

          {!IsProductAvailable() && (
            <h3 className="error-msg">{t("product_no_products")}</h3>
          )}

          {activePopup.type === "change" && (
            <ProductChangePopup
              onCloseClick={() =>
                setActivePopup({
                  type: null,
                  productId: null,
                  section: null,
                })
              }
              productInfo={selectedProductToConfirm}
              currentProductInfo={getSameTypeSelectedProduct(
                selectedProductToConfirm
              )}
              onProductChangeConfirmed={onProductChangeConfirmed}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ProductPanal;
